import { fetchJson } from "src/utils/apiUtils";

const endpoint = `system`;

export const reloadRemyndMasterCache = async () => {
  return fetchJson<IResponse<null>>(`${endpoint}/reloadRemyndMasterCache`, {
    method: "GET"
  });
};

export const syncPartnerProductToRemynd = async () => {
  return fetchJson<IResponse<null>>(`${endpoint}/syncPartnerProductToRemynd`, {
    method: "GET"
  });
};

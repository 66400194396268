import React from "react";
import { Button } from "@material-ui/core";
import { GetApp as ButtonIcon } from "@material-ui/icons";
import useApiAlert from "src/hooks/useApiAlert";
import useHttpErrorHandler from "src/hooks/useHttpErrorHandler";
import { scrapeFromPartnerId, getCurrentState } from "../api";
import { ScrapePartnerState } from "../types";

interface Props {
  partnerId: PartnerId;
}

const ScrapePartnerProductButton: React.FC<Props> = props => {
  const { partnerId } = props;

  const { setMessage } = useApiAlert();
  const { handleError } = useHttpErrorHandler();

  const [fetching, setFetching] = React.useState(false);
  const [currentState, setCurrentState] =
    React.useState<ScrapePartnerState>("SCRAPING");

  const handleClick = React.useCallback(async () => {
    setFetching(true);
    try {
      await scrapeFromPartnerId(partnerId);
      setFetching(false);
      setCurrentState("WAITING_TO_SCRAPE");
      setMessage("success", `สั่ง Run Job Scrape ข้อมูลจาก Partner สำเร็จ`);
    } catch (e) {
      handleError(e);
      setFetching(false);
    }
  }, [partnerId, setMessage, handleError]);

  const refreshCurrentState = React.useCallback(async () => {
    try {
      const result = await getCurrentState(partnerId);
      setCurrentState(result.value);
    } catch (e) {
      handleError(e);
      setCurrentState("SCRAPING");
    }
  }, [partnerId, handleError]);

  React.useEffect(() => {
    refreshCurrentState();
  }, [refreshCurrentState]);

  return (
    <Button
      variant="text"
      type="button"
      size="small"
      startIcon={<ButtonIcon />}
      onClick={handleClick}
      disabled={currentState !== "IDLE" || fetching}
    >
      Scrape ข้อมูล Partner Product จาก SSW (สถานะปัจจุบัน {currentState})
    </Button>
  );
};

export default ScrapePartnerProductButton;

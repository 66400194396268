import { fetchJson } from "src/utils/apiUtils";
import {
  IRemyndBrand,
  IRemyndBrandContainer,
  IRemyndBrandPaginateReq,
  IRemyndBrandSaveReqContainer,
  ScrapeBrandStateStatus
} from "src/apps/modules/remyndBrand/types";

const endpoint = "master/remyndBrand";

export const list = async (search: IRemyndBrandPaginateReq) => {
  return fetchJson<IResponse<IPaginateResponse<IRemyndBrand>>>(
    `${endpoint}/listPaginate`,
    {
      method: "POST",
      body: JSON.stringify({ ...search })
    }
  );
};

export const get = async (id: RemyndBrandId) => {
  return fetchJson<IResponse<IRemyndBrandContainer>>(`${endpoint}/${id}`, {
    method: "GET"
  });
};

export const save = async (req: IRemyndBrandSaveReqContainer) => {
  return fetchJson<IResponse<RemyndBrandId>>(`${endpoint}/save`, {
    method: "POST",
    body: JSON.stringify({ ...req })
  });
};

export const update = async (
  id: RemyndBrandId,
  req: IRemyndBrandSaveReqContainer
) => {
  return fetchJson<IResponse<IRemyndBrandContainer>>(
    `${endpoint}/update/${id}`,
    {
      method: "POST",
      body: JSON.stringify({ ...req })
    }
  );
};

export const deleteMultiple = async (req: ICommonIdsReq) => {
  return fetchJson<IResponse<null>>(`${endpoint}/delete`, {
    method: "DELETE",
    body: JSON.stringify({ ...req })
  });
};

export const scrapeAllSupported = async () => {
  return fetchJson<IResponse<boolean>>(`${endpoint}/scrapeAllSupported`, {
    method: "GET"
  });
};

export const scrapeFromBrandId = async (id: RemyndBrandId) => {
  return fetchJson<IResponse<boolean>>(`${endpoint}/scrapeFromBrandId/${id}`, {
    method: "GET"
  });
};

export const getCurrentScrapeState = async (id: RemyndBrandId) => {
  return fetchJson<IResponse<ScrapeBrandStateStatus>>(
    `${endpoint}/getCurrentScrapeState/${id}`,
    {
      method: "GET"
    }
  );
};

import { fetchJson } from "src/utils/apiUtils";
import { ScrapePartnerState } from "./types";

const endpoint = `scrapePartnerProduct`;

export const scrapeFromPartnerId = async (id: PartnerId) => {
  return fetchJson<IResponse<boolean>>(`${endpoint}/${id}`, {
    method: "GET"
  });
};

export const getCurrentState = async (id: PartnerId) => {
  return fetchJson<IResponse<ScrapePartnerState>>(
    `${endpoint}/getCurrentState/${id}`,
    {
      method: "GET"
    }
  );
};

import React from "react";
import { Box, Typography } from "@material-ui/core";
import { MainLayout } from "src/apps/commons/layouts";
import ShowBuildDate from "src/apps/commons/misc/ShowBuildDate";
import ReloadServerCacheButton from "src/apps/modules/system/components/ReloadServerCacheButton";
import SyncPartnerProductsButton from "src/apps/modules/system/components/SyncPartnerProductsButton";
import ScrapeAllSupportedBrandsButton from "src/apps/modules/remyndBrand/components/ScrapeAllSupportedBrandsButton";
import ScrapePartnerProductButton from "src/apps/modules/scrapePartnerProduct";

const Page = () => (
  <div style={{ paddingLeft: 30 }}>
    <ShowBuildDate />

    <Box mt={4}>
      <Typography variant="h6" color="primary" gutterBottom>
        Admin Tools
      </Typography>
    </Box>
    <ReloadServerCacheButton />
    <SyncPartnerProductsButton />
    <ScrapeAllSupportedBrandsButton />
    <ScrapePartnerProductButton partnerId="08afd3b5-dcd8-4690-8793-cce217a431e1" />
  </div>
);
Page.Layout = MainLayout;

export default Page;

import React, { useCallback, useState } from "react";
import { Button } from "@material-ui/core";
import { Sync as SyncIcon } from "@material-ui/icons";
import { ConfirmDialog } from "src/apps/commons/dataDisplays";
import useApiAlert from "src/hooks/useApiAlert";
import useHttpErrorHandler from "src/hooks/useHttpErrorHandler";
import * as Api from "../api";

const SyncPartnerProductsButton = () => {
  const { setMessage } = useApiAlert();
  const { handleError } = useHttpErrorHandler();

  const [fetching, setFetching] = useState(false);
  const [confirmDialogState, setConfirmDialogState] = useState<{
    open: boolean;
    onConfirm?: () => void;
  }>({ open: false });

  const handleCloseDialog = useCallback(() => {
    setConfirmDialogState({ open: false, onConfirm: undefined });
  }, []);
  const handleClick = useCallback(() => {
    if (!fetching) {
      setConfirmDialogState({
        open: true,
        onConfirm: async () => {
          setFetching(true);
          try {
            await Api.syncPartnerProductToRemynd();
            setFetching(false);
            setMessage("success", "Sync partner products success");
          } catch (e) {
            handleError(e);
            setFetching(false);
          }
          handleCloseDialog();
        }
      });
    }
  }, [fetching, handleError, setMessage, handleCloseDialog]);

  return (
    <>
      <Button
        variant="text"
        type="button"
        size="small"
        startIcon={<SyncIcon />}
        onClick={handleClick}
      >
        Sync ALL Partner Products to Remynd Products
      </Button>
      <ConfirmDialog
        title="Sync ALL Partner Products to Remynd Products?"
        description={`ทำการ insert/update (ไม่มี delete) ข้อมูลในตาราง remynd_product ตามข้อมูลใน partner_product (ปกติจะ auto sync อยู่แล้วถ้าแก้ผ่านหน้าจอของ partner)`}
        open={confirmDialogState.open}
        onClose={handleCloseDialog}
        onConfirm={confirmDialogState.onConfirm}
        confirming={fetching}
      />
    </>
  );
};

export default SyncPartnerProductsButton;

import React from "react";
import { formatDistance } from "date-fns";
import { formatDateTime } from "src/utils";

const buildDate = process.env.BUILD_DATE
  ? formatDateTime(process.env.BUILD_DATE)
  : "unknown time";

const dateTimeDistance = () => {
  try {
    const date = process.env.BUILD_DATE
      ? new Date(process.env.BUILD_DATE)
      : undefined;
    const str = date
      ? formatDistance(date, new Date(), { addSuffix: true })
      : undefined;
    return str ? ` (${str})` : ``;
  } catch (e) {
    return ``;
  }
};

const ShowBuildDate = () => {
  return (
    <p>
      Web deployed at: {buildDate}
      {dateTimeDistance()}
    </p>
  );
};

export default ShowBuildDate;

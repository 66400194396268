import React, { useCallback, useState } from "react";
import { Button } from "@material-ui/core";
import { GetApp as ButtonIcon } from "@material-ui/icons";
import { ConfirmDialog } from "src/apps/commons/dataDisplays";
import useApiAlert from "src/hooks/useApiAlert";
import useHttpErrorHandler from "src/hooks/useHttpErrorHandler";
import { scrapeAllSupported } from "../api";

const ScrapeAllSupportedBrandsButton: React.FC<unknown> = _ => {
  const { setMessage } = useApiAlert();
  const { handleError } = useHttpErrorHandler();

  const [fetching, setFetching] = useState(false);
  const [confirmDialogState, setConfirmDialogState] = useState<{
    open: boolean;
    onConfirm?: () => void;
  }>({ open: false });

  const handleCloseDialog = useCallback(() => {
    setConfirmDialogState({ open: false, onConfirm: undefined });
  }, []);
  const handleClick = useCallback(() => {
    if (!fetching) {
      setConfirmDialogState({
        open: true,
        onConfirm: async () => {
          setFetching(true);
          try {
            await scrapeAllSupported();
            setFetching(false);
            setMessage("success", "Enable scrape jobs success");
          } catch (e) {
            handleError(e);
            setFetching(false);
          }
          handleCloseDialog();
        }
      });
    }
  }, [fetching, handleError, setMessage, handleCloseDialog]);

  return (
    <>
      <Button
        variant="text"
        type="button"
        size="small"
        startIcon={<ButtonIcon />}
        onClick={handleClick}
      >
        เปิด Job Scrape สินค้าทุกแบรนด์ที่รองรับ
      </Button>
      <ConfirmDialog
        title="เปิด Job Scrape สินค้าทุกแบรนด์ที่รองรับ?"
        description={`ปรับสถานะ แบรนด์ที่ปัจจุบันเป็น IDLE -> WAITING_TO_SCRAPE
(monitor สถานะที่ server logs)`}
        open={confirmDialogState.open}
        onClose={handleCloseDialog}
        onConfirm={confirmDialogState.onConfirm}
        confirming={fetching}
      />
    </>
  );
};

export default ScrapeAllSupportedBrandsButton;
